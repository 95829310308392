<template>
  <div class="page-insurance animate__animated animate__fadeIn pt-4">

    <img
     :src="require('@/assets/banners/update-utilities.png')"
      class="w-100 mb-4"
      @load="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
      style="border-radius: 120px; display: none"
      alt=""
    />

    <div id="animation" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <!-- Which insurances would you like to update -->
    <h5 class="fs-la">Which insurances would you like to update?</h5>
    <div class="d-flex mb-4">
      <div
        v-for="(insurance, index) in insuranceTypes"
        :key="index"
        class="m-1 p-1 squares h-100 hvr me-2"
        :class="{ 'hvr-radial-out': !isMobile(), selected: insuraceTypeSelected == insurance['title']  }"
        @click="updateValue('insuraceTypeSelected', insurance['title'])"
      >

        <img
          :src="`${urlBucket}/form/assets/${insurance.img}`"
          width="40"
          :alt="`${insurance.type}`"
          style="display: none"
          @load="
            (e) => {
              e.target.style.display = 'unset';
              hideSpinner(`${insurance['type']}`);
            }
          "
        />
        <div :id="`${insurance['type']}`" class="text-center my-2">
          <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
        </div>
        <p class="fw-bold mb-0 fs-sm">{{ insurance["title"] }}</p>
      </div>
    </div>

    <div class="mb-3" v-if="insuraceTypeSelected">
      <h5 class="fs-la">Select {{ insuraceTypeSelected }} insurance provider</h5>

      <div class="input-search-container mb-2">
        <div class="container-search-icon">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          class="w-100 fs-md"
          label="insurer"
          :clearable="false"
          :options="switchOptions()"
          :placeholder="`e.g. 'Aviva'`"
          :multiple="false"
          @option:selected="(ev) => selectChange(ev)"
          @search="onSearch"
        >
          <template slot="no-options">
            <div class="text-center">
              <img :src="require('@/assets/binoculars.png')" class="mb-3" alt="" />

              <p class="mb-1">We didn't find anything for '{{ search }}'</p>
              <!-- <p
                class="enter-manually"
                @click="
                  () => {
                    updateValue('gpsManual', true);
                    deleteGPS(0);
                  }
                "
              >
                Enter manually
              </p> -->
            </div>
          </template>
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center py-1">
              <img
                :src="`${urlBucket}/${option.icon}`"
                width="30px"
                class="me-3"
                style="display: none"
                @load="
                  (e) => {
                    e.target.style.display = 'unset';
                    hideSpinner(option.insurer);
                  }
                "
              />
              <div :id="option.insurer" class="text-center me-3">
                <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
              </div>
              <p class="mb-0 fs-md">{{ option.insurer }}</p>
            </div>
          </template>
        </vSelect>
      </div>

      <div class="chip-list-wrapper mt-2 mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in insurancesSelection"
          :key="index"
          @click="deleteInsurance(index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.insurer }}</p>
        </div>
      </div>

    </div>

    <h5 class="fs-la mb-3">About this update...<i @click="openInfoTooltip()" class="fa-solid fa-circle-info ms-2"></i></h5>
    <p class="mb-3 fs-sm" style="color: #838089">
      I understand that my insurance company may charge an admin fee to update my address or
      requote me. If this is the case, I understand that my company will reach out to me
    </p>
    <div class="d-flex mb-4">
      <div
        :class="`box-answers fs-md ${informInsurance === true ? 'checked' : ''}`"
        @click="updateValue('informInsurance', true)"
      >
        Yes
      </div>
      <div
        :class="`box-answers fs-md ${informInsurance === false ? 'checked' : ''} ms-3`"
        @click="updateValue('informInsurance', false)"
      >
        No
      </div>
    </div>

    <!-- Label Pages Bottom -->
    <div class="mb-2" v-if="insurancesSelection.length > 0">
      <div class="label-pages d-flex p-3 align-items-center">
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, we'll update your insurance providers about your updates
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import { navigateTo } from "../router/index";
import { isMobile } from "../utils/isMobile";
import { vehicle, life, pet, travel } from "../data/lists.js";
import { setRoute } from '../router/setRoute.js';
// @ is an alias to /src

export default {
  name: "PageInsurance",
  components: { vSelect },
  data() {
    return {
      isMobile,
      insuranceTypes: [
        {
          title: "Life",
          img: "life.png",
          type: "life",
        },
        {
          title: "Pet",
          img: "pet.png",
          type: "pet",
        },
        {
          title: "Travel",
          img: "travel.png",
          type: "travel",
        },
        {
          title: "Vehicle",
          img: "vehicle.png",
          type: "vehicle",
        },
      ],
      search: "",
      errors: {
        insurancesSelection: false,
      },
      vehicle,
      life,
      pet,
      travel,
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    openInfoTooltip() {
      this.$store.commit("setField", ["currentTooltip", "ActionSheetInsurance"]);
      const pushbar = new Pushbar({
        blur: true,
        overlay: true,
      });
      pushbar.open("action-sheet");
    },
    validateInputs() {
      if (this.dentistName) {
        this.errors.dentistsSelected = false;
      } else {
        this.errors.dentistsSelected = this.informDentist ? true : false;
      }
    },
    switchOptions() {
      if (this.insuraceTypeSelected == "Life") {
        return this.life;
      }

      if (this.insuraceTypeSelected == "Pet") {
        return this.pet;
      }

      if (this.insuraceTypeSelected == "Travel") {
        return this.travel;
      }

      if (this.insuraceTypeSelected == "Vehicle") {
        return this.vehicle;
      }
    },
    updateValue(itemName, value) {
      // console.log(value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    onSearch(search, loading) {
      // loading(true)
      this.search = search;
    },
    selectChange(value) {
      // console.log("selectChange", value);
      let array = this.insurancesSelection;

      if (array.includes(value)) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      } else {
        array.push(value);
      }

      this.$store.commit("formStore/setField", ["insurancesSelection", array]);
    },
    deleteInsurance(index) {
      let array = this.insurancesSelection;
      array.splice(index, 1);
      this.$store.commit("formStore/setField", [`insurancesSelection`, array]);
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "Insurances"]);

    const validatePage = () => {
      // let route = "/about-you";
      // const setRoute = () => {
      //   if (this.informPets) {
      //     route = "/pets";
      //     return;
      //   }

      //   if (
      //     this.informPension ||
      //     this.informCharities ||
      //     this.informGyms ||
      //     this.informHomecare ||
      //     this.informBreakdown
      //   ) {
      //     route = "/general-info-3";
      //     return;
      //   }

      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }

      //   if (this.informCouncilTax || this.informWaterSewerage || this.informEnergy) {
      //     route = "/councils";
      //     return;
      //   }

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }
      // };

      // setRoute();

      this.validateInputs();

      if (!this.informInsurance) this.$store.commit("formStore/setField", [`insurancesSelection`, []]);

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "informPension",
      "informCouncilTax",
      "informWaterSewerage",
      "informCharities",
      "informGyms",
      "informBanks",
      "informLoyalty",
      "informMobile",
      "informNHS",
      "informDentist",
      "informEnergy",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informOpticians",
      "insurancesSelection",
      "insuraceTypeSelected",
    ]),
    ...mapGetters("formStore", ["state"]),
  },
};
</script>
<style lang="scss">
.page-insurance {

  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;
    transition: all 0.1s ease-in-out;

    img {
      width: 60%;
    }
  }

  .selected {
    background-color: #1ea6d4;
    color: white !important;
  }

  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .box-answers {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 14px 34px;
    text-align: center;
    cursor: pointer;
  }

  .box-answers-services {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    width: 110px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__actions {
      margin-right: 5px;
    }

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .chip {
      display: flex;
      align-items: center;
      padding: 7px;
      height: 30px;
      border-radius: 25px;
      background-color: #f1f1f1;
      cursor:pointer;

      p {
        color: black;
        font-size: 14px;
      }

      .fa-circle-check {
        color: #1e9d68;
        font-size: 16px;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}
</style>
